import VideoPlayer from './VideoPlayer';
import { videoStreams } from './videoStreams';
import './App.css'
import { useState, useEffect, useRef } from 'react'; // {{ edit_1 }} Import useEffect and useRef

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleDarkMode = () => setIsDarkMode(prevMode => !prevMode);

  const videoRefs = useRef([]); // {{ edit_2 }} Create a ref to store video elements

  const takePicture = (index) => {
    const video = videoRefs.current[index]; // Get the video element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    canvas.width = video.videoWidth; // Set canvas width
    canvas.height = video.videoHeight; // Set canvas height
    context.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw the video frame

    // Convert canvas to image and do something with it (e.g., download)
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'snapshot.png';
    link.click();
  };

  useEffect(() => {
    document.title = "GT Photobooth";
  }, []);

  return (
    <>
      <div className={`min-h-screen flex flex-col items-center justify-center p-8 transition-colors duration-300 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}> {/* {{ edit_1 }} Added transition for smooth color change */}
        <div className="absolute top-4 right-4">
          <button 
            onClick={toggleDarkMode} 
            className={`flex items-center justify-center w-10 h-10 rounded-full transition duration-300 
                        ${isDarkMode ? 'bg-gold text-black hover:bg-blue-600' : 'bg-gray-300 text-black hover:bg-gray-400'}`} // {{ edit_2 }} Updated button styles for Georgia Tech colors
          >
            {isDarkMode ? '🌙' : '☀️'}
          </button>
        </div>
        <h1 className={`text-6xl font-bold mb-4 text-center ${isDarkMode ? 'text-gold' : 'text-black'}`}>Georgia Tech Photobooth 📸</h1> {/* {{ edit_1 }} Added text-center class for mobile view */}
        <p className={`text-lg mb-10 text-center max-w-2xl ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          Capture your favorite moments with our live streams! Take pictures on campus using Atlanta traffic cameras to chronicle your time at Georgia Tech.
        </p>
        <div className="flex justify-center">
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
            {videoStreams.map((stream, index) => (
              <div key={index} className={`border-2 ${isDarkMode ? 'border-yellow-600' : 'border-blue-900'} rounded-lg shadow-lg overflow-hidden bg-white max-w-full transition-transform transform hover:scale-105`}> {/* {{ edit_4 }} Updated border color */}
                <VideoPlayer 
                  ref={el => videoRefs.current[index] = el} // {{ edit_3 }} Assign video element to ref
                  options={stream.options} 
                  className="w-full h-auto" 
                />
                <div className="p-4 flex justify-between"> {/* {{ edit_1 }} Changed to justify-between for left alignment */}
                  <div> {/* {{ edit_2 }} Added a wrapper for title and description */}
                    <h2 className={`text-lg font-bold text-black`}>{stream.title}</h2>
                    <p className={`text-gray-600`}>{stream.description}</p>
                  </div>
                  <button 
                    onClick={() => takePicture(index)} // {{ edit_4 }} Call takePicture with index
                    className={`mt-2 p-2 bg-yellow-500 text-black hover:bg-yellow-600 rounded-lg shadow-lg transition duration-300 transform hover:scale-105 font-bold`}>Take Picture!</button> {/* {{ edit_1 }} Set fixed color for the button */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <p className="text-center text-gray-600 mt-4">More streams coming soon...</p> {/* {{ edit_1 }} Moved text below the grid */}
      </div>
      <footer className="fixed bottom-0 left-0 w-full text-center p-4 bg-blue-100 text-gray-800">
        Made with ❤️ by <a href="https://github.com/siddhxrth" className="underline">Siddharth Lohani</a>
      </footer>
    </>
  )
}

export default App