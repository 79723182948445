let proxy = "http://34.74.56.137:443/proxy"
// let proxy = "http://localhost:5050/proxy"

export const videoStreams = [
    {
      title: "North Ave @ Techwood Dr",
      description: "Camera facing West in front of North Avenue Dining Hall",
      address: "https://sfs-lr-34.dot.ga.gov/rtplive/ATL-CCTV-0939/playlist.m3u8",
      options: {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        muted: true,
        sources: [{
          src: `${proxy}?url=${encodeURIComponent("https://sfs-lr-34.dot.ga.gov/rtplive/ATL-CCTV-0939/playlist.m3u8")}`,
          type: 'application/x-mpegURL'
        }],
      },
    },
    {
      title: "SR 3 / Northside Dr at 10th St",
      description: "Near Rocky Mountain Pizza",
      address: "https://sfs-lr-34.dot.ga.gov/rtplive/ATL-CCTV-0939/playlist.m3u8",
      options: {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        muted: true,
        sources: [{
          src: `${proxy}?url=${encodeURIComponent("https://sfs-lr-34.dot.ga.gov/rtplive/ATL-CCTV-0939/playlist.m3u8")}`,
          type: 'application/x-mpegURL'
        }],
      },
    },
    // Add more streams as needed
  ];
